<div class="p-3" *ngIf="!loadingReserva; else spinner">
  <div class="mt-3 max-w-md mx-auto bg-white rounded-xl shadow-md md:max-w-4xl p-3">
    <span class="text-xl font-bold text-gray-600 pt-3 pl-3" id="moradorPrincipal">Morador principal</span>
    <div *ngIf="show === 'principal'; else cardMoradorPrincipal">
      <app-personal-form [pessoa]="fichaCadastral.moradorPrincipal" (saveForm)="savePrincipalResident($event)"
        [allValidations]="true"></app-personal-form>
    </div>
    <ng-template #cardMoradorPrincipal>
      <app-personal-view *ngIf="fichaCadastral?.moradorPrincipal?.nome" [pessoa]="fichaCadastral.moradorPrincipal"
        (click)="show = 'principal'"></app-personal-view>
    </ng-template>
  </div>

  <div class="mt-3 max-w-md mx-auto bg-white rounded-xl shadow-md md:max-w-4xl p-3">
    <span class="text-xl font-bold text-gray-600 pt-3 pl-3" id="locatario">Locatário</span>
    <div class="form-control md:w-1/3 w-full">
      <ng-select class="form-field" type="text" id="tipo" bindLabel="label" bindValue="value"
        [items]="tipoLocatarioList" [(ngModel)]="typeRent" (ngModelChange)="changeRenter($event)">
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div><span>{{item.label}}</span></div>
        </ng-template>
      </ng-select>
      <label class="form-label" for="tipo">Tipo de pessoa </label>
    </div>
    <div *ngIf="show === 'renter'; else cardRenter">
      <app-personal-form [pessoa]="fichaCadastral.locatario" (saveForm)="submitLocatario($event)"
        [allValidations]="true"></app-personal-form>
    </div>
    <ng-template #cardRenter>
      <app-personal-view *ngIf="fichaCadastral?.locatario?.nome" [pessoa]="fichaCadastral.locatario"
        (click)="show = 'renter'"></app-personal-view>
    </ng-template>
  </div>

  <div class="mt-3 max-w-md mx-auto bg-white rounded-xl shadow-md md:max-w-4xl p-3">
    <div class="flex justify-between items-center">
      <span class="text-xl font-bold text-gray-600">Moradores</span>
      <button class="btn btn-primary" type="submit" (click)="addMorador()">Adicionar</button>
    </div>
    <div class="mt-3" *ngFor="let resident of fichaCadastral.demaisMoradores; let i = index">
      <div class="flex justify-between items-center">
        <span class=" text-xl font-bold text-gray-600" [id]="'resident'+i">Morador {{i + 1}}</span>
        <button class="btn btn-danger" type="submit" (click)="removeMorador(i)">Remover</button>
      </div>
      <app-personal-form [pessoa]="resident" (saveForm)="submitMorador($event, i)"
        *ngIf="show === 'resident'+i; else cardMorador" [allValidations]="false"></app-personal-form>
      <ng-template #cardMorador>
        <app-personal-view [pessoa]="resident" (click)="show = 'resident'+i"></app-personal-view>
      </ng-template>
    </div>
  </div>
  <div *ngIf="!sendingForm; else sending" class="mt-3 max-w-md mx-auto bg-white shadow-md md:max-w-4xl">
    <button class="btn btn-secondary w-full" type="submit" (click)="enviarFicha()">Enviar Ficha</button>
  </div>
  <ng-template #sending>
    <div class="flex justify-center mt-3">
      <div class="spinner"></div>
    </div>
  </ng-template>

</div>
<ng-template #spinner>
  <div class="flex justify-center">
    <div class="spinner"></div>
  </div>
</ng-template>
