<div class="not-found-content">
  <div class="hit-the-floor">404</div>
  <div class="mt-3 max-w-md mx-auto  md:max-w-4xl p-3 flex flex-col items-center text-center">
    <p class="text-2xl font-bold text-gray-600">Not Found</p>
    <p class="max-w-md text-lg font-bold text-gray-600">Ficha cadastral não encontrada ou o link pode estar expirado.
      Entre em contato com o nosso time
      para mais
      informações
    </p>
  </div>
</div>