import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalViewComponent } from './personal-view/personal-view.component';
import { PersonalFormComponent } from './personal-form/personal-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorFieldComponent } from './error-field/error-field.component';
import { ValidationPipe } from './validation.pipe';
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputPhoneComponent } from './input-phone/input-phone.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
  dropSpecialCharacters: false,
};

@NgModule({
  declarations: [
    PersonalViewComponent,
    PersonalFormComponent,
    ErrorFieldComponent,
    ValidationPipe,
    InputPhoneComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgSelectModule,
  ],
  exports: [PersonalViewComponent, PersonalFormComponent],
  providers: [provideNgxMask(maskConfig)],
})
export class ComponentsModule {}
