import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { PaisDTO } from 'src/app/core/types';

const ddiBrasil = {
  uuid: 'f9aa1993-7795-47ae-9412-13ab4e08677f',
  nome: 'BRASIL',
  nomeIngles: ' BRAZIL',
  sigla: 'BR',
  ddi: '+55',
};
@Component({
  selector: 'ngx-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
})
export class InputPhoneComponent implements OnChanges {
  @Input() paisList: PaisDTO[] = [];
  @Input() phone: string = '';

  @Output() phoneChange = new EventEmitter<string>();

  public phoneNumber: string = '';
  public phoneDDI: PaisDTO = ddiBrasil;

  ngOnChanges(): void {
    if (this.phone) {
      let phoneDesc = this.phone?.split(' ');
      this.phoneNumber = phoneDesc[1];
      this.phoneDDI =
        this.paisList.find((el) => el.ddi === phoneDesc[0]) ?? ddiBrasil;
    }
  }

  onPhoneChange() {
    this.phoneChange.emit(`${this.phoneDDI.ddi} ${this.phoneNumber}`);
  }

  resetNumber() {
    this.phoneNumber = '';
  }

  customSearchFn(term: string, item: PaisDTO) {
    term = term.toLowerCase();
    return item.nome.toLowerCase().indexOf(term) > -1;
  }
}
