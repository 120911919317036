import { AbstractControl, FormGroup } from '@angular/forms';

export class Validacoes {
  static ValidaCpf(controle: AbstractControl) {
    const digits = controle.value
      ?.replace(/[^0-9]/g, '')
      .split('')
      .map((digit: string) => parseInt(digit));

    if (digits?.length !== 11 || allDigitsAreSame(digits)) {
      return { cpfInvalido: true };
    }

    if (
      !validateCpfVerifyingDigit(digits, 9) ||
      !validateCpfVerifyingDigit(digits, 10)
    ) {
      return { cpfInvalido: true };
    }

    return null;
  }

  static validaCNPJ(controle: AbstractControl) {
    const digits = controle.value
      .replace(/[^0-9]/g, '')
      .split('')
      .map((digit: string) => parseInt(digit));

    if (digits.length !== 14 || allDigitsAreSame(digits)) {
      return { cnpjInvalido: true };
    }

    if (
      !validateCnpjVerifyingDigit(digits, 12) ||
      !validateCnpjVerifyingDigit(digits, 13)
    ) {
      return { cnpjInvalido: true };
    }

    return null;
  }

  static MaiorQue18Anos(controle: AbstractControl) {
    const nascimento = controle.value;
    const [ano, mes, dia] = nascimento.split('-');
    const hoje = new Date();
    const dataNascimento = new Date(+ano, +mes, +dia, 0, 0, 0);
    const tempoParaTeste = 1000 * 60 * 60 * 24 * 365 * 18; //18 anos em mili segundos...

    if (hoje.getTime() - dataNascimento.getTime() >= tempoParaTeste)
      return null;

    return { menorDeIdade: true };
  }
}

function validateCpfVerifyingDigit(digits: number[], index: 9 | 10): boolean {
  let sum = 0;
  for (let i = 0, coefficient = index + 1; i < index; i++, coefficient--) {
    sum += digits[i] * coefficient;
  }
  let verifyingDigit = ((sum * 10) % 11) % 10;

  if (verifyingDigit !== digits[index]) {
    return false;
  }

  return true;
}

function allDigitsAreSame(digits: number[]) {
  for (let i = 1; i < digits.length; i++) {
    if (digits[i] !== digits[0]) {
      return false;
    }
  }

  return true;
}

function validateCnpjVerifyingDigit(digits: number[], index: 12 | 13): boolean {
  let sum = 0;
  let coefficient = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  if (index === 13) {
    coefficient = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  }

  for (let i = 0; i < coefficient.length; i++) {
    sum += digits[i] * coefficient[i];
  }

  let mod = sum % 11;
  let verifyingDigit = mod === 0 || mod === 1 ? 0 : 11 - mod;

  if (verifyingDigit !== digits[index]) {
    return false;
  }

  return true;
}
