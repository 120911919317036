<form class="flex flex-wrap" [formGroup]="formPersonal">
  <div class="form-control md:w-1/3 w-full">
    <ng-select class="form-field" type="text" id="tipo" formControlName="tipo" bindLabel="label" bindValue="value"
      [items]="tipoPessoaList" (change)="changeTipoPessoa()">
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div><span>{{item.label}}</span></div>
      </ng-template>
    </ng-select>
    <label class="form-label required" for="tipo">Tipo de pessoa <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['tipo']" [errorMessages]="validationMessages.tipo">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA' || tipoPessoa === 'JURIDICA'">
    <ng-select class="form-field" type="text" id="tipoDocumento" formControlName="tipoDocumento"
      (change)="changeTypeDoc()" [items]="tipoDocumentoList" bindLabel="label" bindValue="value">
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        {{item.label}}
      </ng-template>

    </ng-select>
    <label class="form-label" for="tipo">Tipo de documento <span *ngIf="!isPjEstrangeira()" class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['tipoDocumento']" [errorMessages]="validationMessages.tipoDocumento">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA' || tipoPessoa === 'JURIDICA'">
    <input class="form-field" type="text" id="documento" formControlName="documento" placeholder=""
      [mask]="getMaskDocument()" (change)="getPessoaDoc()" maxlength="18"/>
    <label class="form-label" for="documento">Documento <span *ngIf="!isPjEstrangeira()" class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['documento']" [errorMessages]="validationMessages.documento">
    </error-field>
  </div>



  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA' || tipoPessoa === 'JURIDICA'">
    <input class="form-field" type="text" id="personName" formControlName="nome" placeholder="" maxlength="100"/>
    <label class="form-label" for="personName">Nome <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['nome']" [errorMessages]="validationMessages.nome">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA'">
    <input class="form-field" type="text" id="nomeChamado" formControlName="nomeChamado" placeholder="" maxlength="100"/>
    <label class="form-label" for="nomeChamado">Gostaria de ser chamado de</label>
    <error-field [control]="formPersonal.controls['nomeChamado']" [errorMessages]="validationMessages.nomeChamado">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA'">
    <input class="form-field" type="date" id="dataNascimento" formControlName="dataNascimento"
      placeholder="Data de nascimento" />
    <label class="form-label" for="dataNascimento">Data nascimento <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['dataNascimento']"
      [errorMessages]="validationMessages.dataNascimento">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA' || tipoPessoa === 'JURIDICA'">
    <input class="form-field" type="text" id="email" formControlName="email" placeholder="" maxlength="60"/>
    <label class="form-label" for="email">E-mail <span class="text-red-600" *ngIf="allValidations">*</span></label>
    <error-field [control]="formPersonal.controls['email']" [errorMessages]="validationMessages.email">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA' || tipoPessoa === 'JURIDICA'">
    <!-- <input class="form-field" type="text" id="telefone" formControlName="telefone" placeholder="" /> -->
    <ngx-input-phone [paisList]="paisList" [phone]="formPersonal.value.telefone"
      (phoneChange)="phoneChange('telefone', $event)"></ngx-input-phone>
    <label class="form-label" for="telefone">Telefone</label>
    <error-field [control]="formPersonal.controls['telefone']" [errorMessages]="validationMessages.telefone">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA' || tipoPessoa === 'JURIDICA'">
    <ngx-input-phone [paisList]="paisList" [phone]="formPersonal.value.celular"
      (phoneChange)="phoneChange('celular', $event)"></ngx-input-phone>
    <!-- <input class="form-field" type="text" id="celular" formControlName="celular" placeholder=""
      mask="(00) 0000-0000 || (00) 0 0000-0000" /> -->
    <label class="form-label" for="celular">Celular <span class="text-red-600" *ngIf="allValidations">*</span></label>
    <error-field [control]="formPersonal.controls['celular']" [errorMessages]="validationMessages.celular">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA'">
    <input class="form-field" type="text" id="profissao" formControlName="profissao" placeholder="" maxlength="30"/>
    <label class="form-label" for="celular">Profissão</label>
    <error-field [control]="formPersonal.controls['profissao']" [errorMessages]="validationMessages.profissao">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA'">
    <input class="form-field" type="text" id="empresaQueTrabalha" formControlName="empresaQueTrabalha" placeholder="" maxlength="100"/>
    <label class="form-label" for="empresaQueTrabalha">Empresa que trabalha</label>
    <error-field [control]="formPersonal.controls['empresaQueTrabalha']"
      [errorMessages]="validationMessages.empresaQueTrabalha">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA'">
    <ng-select class="form-field" type="text" id="paisOrigem" formControlName="paisOrigem" bindLabel="nome"
      [items]="paisList">
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div><span>{{item.nome}}</span></div>
      </ng-template>
    </ng-select>
    <label class="form-label" for="tipo">Nacionalidade <span class="text-red-600" *ngIf="allValidations">*</span></label>
    <error-field [control]="formPersonal.controls['paisOrigem']" [errorMessages]="validationMessages.paisOrigem">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA'">
    <ng-select class="form-field" type="text" id="genero" formControlName="genero" bindLabel="nome"
      [items]="generoList">
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div><span>{{item.nome}}</span></div>
      </ng-template>
    </ng-select>
    <label class="form-label" for="genero" *ngIf="tipoPessoa === 'FISICA'">Gênero <span class="text-red-600" *ngIf="allValidations">*</span></label>
    <error-field [control]="formPersonal.controls['genero']" [errorMessages]="validationMessages.genero">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="tipoPessoa === 'FISICA'">
    <!-- <ng-select class="form-field" type="text" id="tipoLogradouro" formControlName="possuiPet">
      <ng-option [value]="false">
        Não
      </ng-option>
      <ng-option [value]="true">
        Sim
      </ng-option>
    </ng-select> -->
    <label class="inline-flex items-center cursor-pointer mt-3">
      <input type="checkbox" formControlName="possuiPet" class="sr-only peer mt-2">
      <div class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-['']  after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{formPersonal.controls['possuiPet'].value? 'Sim' : 'Não'}}</span>
    </label>
    <label class="form-label" for="tipo">Possui pet?</label>
    <error-field [control]="formPersonal.controls['possuiPet']"
      [errorMessages]="validationMessages.possuiPet ">
    </error-field>
  </div>

  <div class="form-control w-full">
    <span class="text-lg font-bold text-gray-600">Endereço</span>
  </div>

  <div class="form-control md:w-1/3 w-full">
    <ng-select class="form-field" type="text" id="paisEndereco" formControlName="paisEndereco" bindLabel="nome"
      [items]="paisList" (change)="changePais()" >
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div><span>{{item.nome}}</span></div>
      </ng-template>
    </ng-select>
    <label class="form-label" for="tipo">País <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['paisEndereco']" [errorMessages]="validationMessages.paisEndereco">
    </error-field>
  </div>


  <div class="form-control md:w-1/3 w-full" *ngIf="paisEndereco">
    <input class="form-field" type="text" id="cep" formControlName="cep" placeholder="" (change)="searchCep()"
      name="cep" [mask]="getCepMask()" maxlength="10" [validation]="false" />
    <label class="form-label" for="personName">{{paisEndereco === 'BRASIL'?
      "CEP" : "Zip code"}} <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['cep']" [errorMessages]="validationMessages.cep">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="paisEndereco === 'BRASIL'">
    <ng-select class="form-field" type="text" id="tipoLogradouro" formControlName="tipoLogradouro"
    bindLabel="Aeroporto" bindValue="nomePortugues">
      <ng-option *ngFor="let logradouro of tipoLogradouroList" [value]="logradouro">
        {{logradouro.nomePortugues}}
      </ng-option>
    </ng-select>
    <label class="form-label" for="tipo">Tipo Logradouro <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['tipoLogradouro']"
      [errorMessages]="validationMessages.tipoLogradouro ">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="paisEndereco">
    <input class="form-field" type="text" id="logradouro" formControlName="logradouro" placeholder="Logradouro" maxlength="60"/>
    <label class="form-label" for="logradouro">Logradouro <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['logradouro']" [errorMessages]="validationMessages.logradouro">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="paisEndereco">
    <input class="form-field" type="text" id="numero" formControlName="numero" placeholder="Número" maxlength="8"/>
    <label class="form-label" for="numero">Número <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['numero']" [errorMessages]="validationMessages.numero">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="paisEndereco">
    <input class="form-field" type="text" id="complemento" formControlName="complemento" placeholder="Complemento" />
    <label class="form-label" for="complemento">Complemento</label>
    <error-field [control]="formPersonal.controls['complemento']" [errorMessages]="validationMessages.complemento">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="paisEndereco">
    <input class="form-field" type="text" id="bairro" formControlName="bairro" placeholder="Bairro" maxlength="20"/>
    <label class="form-label" for="bairro">Bairro <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['bairro']" [errorMessages]="validationMessages.bairro">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="paisEndereco">
    <input class="form-field" type="text" id="cidade" formControlName="cidade" placeholder="Cidade" maxlength="50"/>
    <label class="form-label" for="cidade">Cidade <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['cidade']" [errorMessages]="validationMessages.cidade">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="paisEndereco === 'BRASIL'">
    <input class="form-field" type="text" id="codigoCidadeIbge" formControlName="codigoCidadeIbge"
      placeholder="Código IBGE" maxlength="7"/>
    <label class="form-label" for="codigoCidadeIbge">Código IBGE <span class="text-red-600" *ngIf="allValidations">*</span></label>
    <error-field [control]="formPersonal.controls['codigoCidadeIbge']"
      [errorMessages]="validationMessages.codigoCidadeIbge">
    </error-field>
  </div>

  <div class="form-control md:w-1/3 w-full" *ngIf="paisEndereco">
    <input class=" form-field" type="text" id="uf" formControlName="uf" placeholder="Estado" maxlength="3"/>
    <label class="form-label" for="uf">Estado <span class="text-red-600">*</span></label>
    <error-field [control]="formPersonal.controls['uf']" [errorMessages]="validationMessages.uf">
    </error-field>
  </div>

  <div class="flex w-full justify-end">
    <button class="btn btn-primary" type="submit" (click)="onSave()">Salvar</button>
  </div>

</form>
