import { FormControl, AbstractControl, FormGroupDirective } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'error-field',
  templateUrl: './error-field.component.html',
  styleUrls: ['./error-field.component.scss'],
  host: { style: 'color: red;' },
})
export class ErrorFieldComponent {
  @Input() control: FormControl | AbstractControl = {} as FormControl;
  @Input() errorMessages: Object = {};

  constructor(public formDirective:FormGroupDirective){
  }
}
