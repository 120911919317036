import { Component, Input } from '@angular/core';
import { PessoaDTO } from 'src/app/core/types';

@Component({
  selector: 'app-personal-view',
  templateUrl: './personal-view.component.html',
  styleUrls: ['./personal-view.component.scss'],
})
export class PersonalViewComponent {
  @Input() pessoa: PessoaDTO = {} as PessoaDTO;

  getMasDoc() {
    switch (this.pessoa.tipoDocumento) {
      case 'CPF':
        return '000.000.000-00';
      case 'CNPJ':
        return '00.000.000/0000-00';
      default:
        return '';
    }
  }
}
