export const tipoPessoaList = [
  {
    value: 'FISICA',
    label: 'Pessoa Física',
  },
  {
    value: 'JURIDICA',
    label: 'Pessoa Jurídica',
  },
];

export const tipoPessoaResidentsList = [
  {
    value: 'FISICA',
    label: 'Pessoa Física',
  },
  {
    value: 'JURIDICA',
    label: 'Pessoa Jurídica',
  },
];

export const tipoDocumentoListPJ = [{ value: 'CNPJ', label: 'CNPJ' }];

export const tipoDocumentoListPF = [
  { value: 'CPF', label: 'CPF' },
  { value: 'PASSAPORTE', label: 'PASSAPORTE' },
  { value: 'RNE', label: 'RNE' },
];

export const formValidations = {
  tipo: {
    required: 'Tipo obrigatório',
  },
  nome: {
    required: 'Nome obrigatório',
  },
  nomeChamado: {},
  dataNascimento: {
    required: 'Data nascimento obrigatório',
    agePrincipal: 'Data nascimento inválida'
  },
  tipoDocumento: {
    required: 'Tipo documento obrigatório',
  },
  documento: {
    required: 'Documento obrigatório',
    cpfInvalido: 'CPF inválido',
    cnpjInvalido: 'CNPJ inválido',
  },
  email: {
    required: 'E-mail obrigatório',
    email: 'E-mail inválido',
  },
  telefone: {
    required: 'Telefone obrigatório',
  },
  celular: {
    required: 'Celular obrigatório',
  },
  empresaQueTrabalha: {
    required: 'Nome obrigatório',
  },
  profissao: {
    required: 'Profissão obrigatório',
  },
  paisOrigem: {
    required: 'Nacionalidade obrigatório',
  },
  genero: {
    required: 'Genero obrigatório',
  },
  possuiPet:{
    required: 'Campo obrigatório'
  },
  paisEndereco: {
    required: 'País obrigatório',
  },
  cep: {
    required: 'CEP obrigatório',
  },
  tipoLogradouro: {
    required: 'Tipo logradouro obrigatório',
  },
  logradouro: {
    required: 'Logradouro obrigatório',
  },
  numero: {
    required: 'Número obrigatório',
  },
  complemento: {},
  bairro: {
    required: 'Bairro obrigatório',
  },
  cidade: {
    required: 'Cidade obrigatório',
  },
  uf: {
    required: 'UF obrigatório',
  },
  codigoCidadeIbge: {
    required: 'Código IBGE obrigatório',
  },
};
