import { FichaCadastralDTO } from './../core/types';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, take } from 'rxjs';
import { ApiService } from '../core/services/api.service';
import { PessoaDTO, ReservaDTO } from '../core/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent {
  public fichaCadastral = {} as FichaCadastralDTO;
  public reserva = {} as ReservaDTO;
  public show: string = '';
  public pessoa = {} as PessoaDTO;
  public typeRent: string | null = null;
  public tipoLocatarioList = [
    { label: 'Morador principal', value: 'PRINCIPAL' },
    { label: 'Outra pessoa', value: 'ANOTHER' },
  ];
  public loadingReserva = false;
  public sendingForm = false;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router
  ) {}

  savePrincipalResident(event: PessoaDTO) {
    this.fichaCadastral.moradorPrincipal = event;
    this.show = '';
  }

  async submitPrincipal(event: PessoaDTO) {
    this.fichaCadastral.moradorPrincipal = event;
    this.show = '';
  }

  async submitLocatario(event: PessoaDTO) {
    if(this.typeRent === "ANOTHER"){
      if(event.tipo === "FISICA"){
        this.fichaCadastral.tipoLocatario = "OUTRA_PESSOA_FISICA";
      }else{
        this.fichaCadastral.tipoLocatario = "PESSOA_JURIDICA";
      }
    }
    this.fichaCadastral.locatario = event;
    this.show = '';
  }

  async submitMorador(event: PessoaDTO, indexMorador: number) {
    this.fichaCadastral.demaisMoradores[indexMorador] = event;
    this.show = '';
  }

  ngOnInit(): void {
    this.loadInitial();
  }

  loadInitial(){
    this.loadingReserva = true;
    this.route.params.subscribe((params) => {
      if (params['uuid']) {
        this.api
          .getFicha(params['uuid'])
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.loadingReserva = false;
              this.reserva = res;
              this.fichaCadastral.reservaUuid = res.uuid;
              this.fichaCadastral.tipoLocatario = this.reserva.contratoCliente.tipoLocatario;
              this.getMoradorPrincipal(res);
              this.getDemaisMoradores(res);
            },
            error: (err) => {
              this.loadingReserva = false;
              this.router.navigate(['/404']);
            },
          });
      }
    });
  }

  getMoradorPrincipal(reserva: ReservaDTO) {
    let morador = reserva.moradores.find((el) => el.moradorPrincipal === true);
    if (morador?.uuid) {
      this.api
        .getPessoa(morador?.pessoaUuid)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.fichaCadastral.moradorPrincipal = res;
            this.getLocatario(reserva);
          },
          error: (err) => {
            this.loadingReserva = false;
            return {} as PessoaDTO;
          },
        });
    } else {
      this.fichaCadastral.moradorPrincipal = {} as PessoaDTO;
      this.show = 'principal';
      this.loadingReserva = false;
    }
  }

  getDemaisMoradores(res: ReservaDTO) {
    let demaisMoradores = res.moradores.filter(
      (el) => el.moradorPrincipal === false
    );
    this.fichaCadastral.demaisMoradores = [];
    demaisMoradores.map((el) => {
      this.api
        .getPessoa(el.pessoaUuid)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.fichaCadastral.demaisMoradores.push(res);
          },
          error: (err) => {
            return;
          },
        });
    });
  }

  getLocatario(res: ReservaDTO) {
    if (res.contratoCliente.tipoLocatario === 'MORADOR_PRINCIPAL') {
      this.fichaCadastral.locatario = this.fichaCadastral.moradorPrincipal;
      this.typeRent = 'PRINCIPAL';
    } else {
      this.typeRent = 'ANOTHER';
      this.api
        .getPessoa(res.contratoCliente.locatario.uuid)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.fichaCadastral.locatario = res;
            this.loadingReserva = false;
          },
          error: (err) => {
            this.loadingReserva = false;
            return {} as PessoaDTO;
          },
        });
    }
  }

  addMorador() {
    if (this.fichaCadastral.demaisMoradores) {
      this.fichaCadastral.demaisMoradores.push({} as PessoaDTO);
    } else {
      this.fichaCadastral.demaisMoradores = [{} as PessoaDTO];
    }
    // this.fichaCadastral.demaisMoradores.push({} as PessoaDTO);
    this.show = `resident${this.fichaCadastral.demaisMoradores.length - 1}`;
    setTimeout(() => {
      let element = document.getElementById(
        `resident${this.fichaCadastral.demaisMoradores.length - 1}`
      );
      element?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  removeMorador(index: number) {
    this.fichaCadastral.demaisMoradores.splice(index, 1);
  }

  changeRenter(event: string) {
    switch (event) {
      case 'PRINCIPAL':
        this.fichaCadastral.locatario = this.fichaCadastral.moradorPrincipal;
        this.fichaCadastral.tipoLocatario = "MORADOR_PRINCIPAL";
        break;
      case 'ANOTHER':
        this.show = 'renter';
        this.fichaCadastral.locatario = {} as PessoaDTO;
        break;
      default:
        this.fichaCadastral.locatario = {} as PessoaDTO;
        this.show = '';
        break;
    }
  }

  enviarFicha() {
    if (
      this.fichaCadastral.moradorPrincipal?.nome &&
      this.fichaCadastral.locatario?.nome
    ) {
      this.sendingForm = true;
      this.api.postFicha({...this.fichaCadastral}).subscribe({
        next: () => {
          this.sendingForm = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Ficha Cadastral enviada com sucesso!',
            showConfirmButton: false,
            timer: 1500,
          }).then(()=>{
            this.loadInitial();
          });
        },
        error: (err) => {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            confirmButtonColor: '#86c2ca',
            text:
              err.error.message ?? 'Algo deu errado ao enviar ficha cadastral.',
            // footer:
            //   '<a href="#">Entre em contato conosco caso o problema persista</a>',
          });
          this.sendingForm = false;
        },
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Formulário incompleto',
        confirmButtonColor: '#86c2ca',
        text: 'O morador principal e o locatário são obrigatórios!',
      });
    }
  }
}
