<div class="input-phone-content">
  <ng-select class="form-field" type="text" id="tipo" [(ngModel)]="phoneDDI" [items]="paisList" (change)="resetNumber()"
    [clearable]="false" [searchFn]="customSearchFn">
    <ng-template ng-label-tmp let-item="item">
      <div class="gap-2 option" *ngIf="phoneDDI">
        <span class="highlight">
          {{ item?.sigla }}
        </span>
        <span>
          {{ item?.ddi }}
        </span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
      <div class="flex align-items-center gap-2 option" *ngIf="phoneDDI">
        <span class="highlight">
          {{ item?.sigla }}
        </span>
        <span>
          {{ item?.ddi }}
        </span>
      </div>
    </ng-template>
  </ng-select>
  <input class="form-field" placeholder="Telefone" [(ngModel)]="phoneNumber" maxlength="20" name="logradouro"
    [prefix]="phoneDDI.ddi" [mask]="
      phoneDDI.ddi === '+55'
        ? ' (00) 0000-0000|| (00) 0 0000-0000'
        : ' 000000000000000'
    " (ngModelChange)="onPhoneChange()" />
</div>
