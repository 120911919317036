import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoint } from 'src/environments';
import {
  Address,
  FichaCadastralDTO,
  GeneroDTO,
  PaisDTO,
  PessoaDTO,
  ReservaDTO,
  TipoLogradouroDTO,
} from '../types';

interface ResponseViaCep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  error?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private url = endpoint(`/api/ficha-cadastral`);

  constructor(private http: HttpClient) {}

  getGeneros(): Observable<GeneroDTO[]> {
    return this.http.get<GeneroDTO[]>(`${this.url}/genero/todos`);
  }

  getFicha(id: string): Observable<ReservaDTO> {
    return this.http.get<ReservaDTO>(`${this.url}/${id}`);
  }

  getPessoa(id: string): Observable<PessoaDTO> {
    return this.http.get<PessoaDTO>(`${this.url}/pessoa/${id}`);
  }

  getPessoaByDoc(doc: string, typeDoc: string): Observable<PessoaDTO> {
    return this.http.get<PessoaDTO>(`${this.url}/${typeDoc}/documento/${doc}`);
  }

  putPessoa(pessoa: PessoaDTO, reservaUuid: string): Observable<PessoaDTO> {
    return this.http.put<PessoaDTO>(
      `${this.url}/${reservaUuid}/altera-pessoa}`,
      pessoa
    );
  }

  addPessoa(pessoa: PessoaDTO, reservaUuid: string): Observable<PessoaDTO> {
    return this.http.post<PessoaDTO>(
      `${this.url}/${reservaUuid}/inserir-pessoa}`,
      pessoa
    );
  }

  getTiposLogradouro(): Observable<TipoLogradouroDTO[]> {
    return this.http.get<TipoLogradouroDTO[]>(
      `${this.url}/tipo-logradouro/todos`
    );
  }

  getPaises(): Observable<PaisDTO[]> {
    return this.http.get<PaisDTO[]>(`${this.url}/todos`);
  }

  getCep(cep: string): Observable<ResponseViaCep> {
    return this.http.get<ResponseViaCep>(
      `https://viacep.com.br/ws/${cep}/json/`
    );
  }

  postFicha(ficha: FichaCadastralDTO): Observable<FichaCadastralDTO> {
    return this.http.post<FichaCadastralDTO>(`${this.url}`, ficha);
  }
}
