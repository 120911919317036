<div class="flex gap-1 flex-wrap p-3">
  <span class="mt-1 text-gray-500">Nome:<span class="font-bold"> {{ pessoa.nome }}</span> </span>
  <span class="mt-1 text-gray-500" *ngIf="pessoa.email">E-mail: <span class="font-bold"> {{ pessoa.email }}</span></span>
  <span class="mt-1 text-gray-500" *ngIf="pessoa?.telefone">Telefone: <span class="font-bold">{{
      pessoa.telefone}}</span></span>
  <span class="mt-1 text-gray-500"  *ngIf="pessoa.celular">Celular: <span class="font-bold"> {{ pessoa.celular }}</span></span>
  <span class="mt-1 text-gray-500"  *ngIf="pessoa.documento">Documento: <span class="font-bold"> {{ pessoa.documento | mask: getMasDoc()
      }}</span></span>
  <span class="mt-1 text-gray-500" *ngIf=" pessoa.profissao">Profissão: <span class="font-bold"> {{ pessoa.profissao
      }}</span></span>
  <span class="mt-1 text-gray-500" *ngIf="pessoa.empresaQueTrabalha">Empresa: <span class="font-bold"> {{
      pessoa.empresaQueTrabalha }}</span></span>
  <span class="mt-1 text-gray-500"> Nascimento:
    <span class="font-bold">{{ pessoa.dataNascimento | date : "dd/MM/yyyy" }}</span>
  </span>
  <span class="mt-1 text-gray-500">
    <span class="font-bold"  *ngIf="pessoa.paisEndereco">{{ pessoa.logradouro}} {{ pessoa.cep}} {{ pessoa.numero}}, {{ pessoa.bairro}}, {{
      pessoa.cidade}} - {{
      pessoa.uf}}</span>
  </span>
</div>
