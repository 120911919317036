import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { forkJoin, map } from 'rxjs';
import {
  formValidations,
  tipoDocumentoListPF,
  tipoDocumentoListPJ,
  tipoPessoaList,
  tipoPessoaResidentsList,
} from 'src/app/core/constants/selects-constants';
import { ApiService } from 'src/app/core/services/api.service';
import {
  GeneroDTO,
  PaisDTO,
  PessoaDTO,
  TipoLogradouroDTO,
} from 'src/app/core/types';
import { Validacoes } from 'src/app/core/utils/form-validators';

interface InitialData {
  paises: PaisDTO[];
  generos: GeneroDTO[];
  logradouros: TipoLogradouroDTO[];
}

@Component({
  selector: 'app-personal-form',
  templateUrl: './personal-form.component.html',
  styleUrls: ['./personal-form.component.scss'],
})
export class PersonalFormComponent implements OnChanges {
  public formPersonal: FormGroup = new FormGroup({
    tipo: new FormControl(),
    nome: new FormControl(),
    nomeChamado: new FormControl(),
    dataNascimento: new FormControl(),
    tipoDocumento: new FormControl(),
    documento: new FormControl(),
    email: new FormControl(),
    telefone: new FormControl(),
    celular: new FormControl(),
    profissao: new FormControl(),
    empresaQueTrabalha: new FormControl(),
    paisOrigem: new FormControl(),
    genero: new FormControl(),
    paisEndereco: new FormControl(),
    cep: new FormControl(),
    tipoLogradouro: new FormControl(),
    logradouro: new FormControl(),
    numero: new FormControl(),
    complemento: new FormControl(),
    bairro: new FormControl(),
    cidade: new FormControl(),
    uf: new FormControl(),
    codigoCidadeIbge: new FormControl(),
  });

  public validationMessages = formValidations;

  public tipoDocumentoList = tipoDocumentoListPF;
  public paisList: PaisDTO[] = [];
  public generoList: GeneroDTO[] = [];
  public tipoLogradouroList: TipoLogradouroDTO[] = [];
  public loadingCep = false;
  public tipoPessoa: 'FISICA' | 'JURIDICA' | null = null;
  public paisEndereco = '';

  @Input() pessoa: PessoaDTO = {} as PessoaDTO;
  @Input() allValidations: boolean = true;
  public tipoPessoaList = tipoPessoaList;
  public tipoPessoaResidentsList = tipoPessoaResidentsList;
  @Output() saveForm: EventEmitter<PessoaDTO> = new EventEmitter<PessoaDTO>();
  constructor(private fb: FormBuilder, private apiService: ApiService) {
    this.loadInitial();
  }

  ngOnChanges(): void {
    this.initForm();
  }

  initForm() {
    this.tipoPessoa = this.pessoa.tipo;
    this.paisEndereco = this.pessoa.paisEndereco?.nome;
    this.formPersonal = this.fb.group({
      tipo: [this.pessoa.tipo, Validators.required],
      nome: [this.pessoa.nome, Validators.required],
      nomeChamado: [this.pessoa.nomeChamado],
      dataNascimento: [this.pessoa.dataNascimento],
      tipoDocumento: [this.pessoa.tipoDocumento],
      documento: [this.pessoa.documento],
      email: [this.pessoa.email, Validators.email],
      telefone: [this.pessoa.telefone],
      celular: [this.pessoa.celular],
      empresaQueTrabalha: [this.pessoa.empresaQueTrabalha, ,],
      profissao: [this.pessoa.profissao],
      paisOrigem: [this.pessoa.paisOrigem],
      genero: [this.pessoa.genero],
      possuiPet: [this.pessoa.possuiPet?? false],
      paisEndereco: [this.pessoa.paisEndereco],
      cep: [this.pessoa.cep],
      tipoLogradouro: [this.pessoa.tipoLogradouro],
      logradouro: [this.pessoa.logradouro],
      numero: [this.pessoa.numero],
      complemento: [this.pessoa.complemento],
      bairro: [this.pessoa.bairro],
      cidade: [this.pessoa.cidade],
      uf: [this.pessoa.uf],
      codigoCidadeIbge: [this.pessoa.codigoCidadeIbge]
    });
    switch (this.formPersonal.value.tipo) {
      case 'FISICA':
        this.tipoDocumentoList = tipoDocumentoListPF;
        this.tipoPessoa = 'FISICA';
        break;
      case 'JURIDICA':
        this.tipoDocumentoList = tipoDocumentoListPJ;
        this.tipoPessoa = 'JURIDICA';
        break;
      default:
        break;
    }
  }

  changeTipoPessoa() {
    switch (this.formPersonal.value.tipo) {
      case 'FISICA':
        this.tipoDocumentoList = tipoDocumentoListPF;
        this.tipoPessoa = 'FISICA';
        this.formPersonal.controls['documento'].reset();
        this.formPersonal.controls['tipoDocumento'].reset();
        break;
      case 'JURIDICA':
        this.formPersonal.controls['documento'].reset();
        this.formPersonal.controls['tipoDocumento'].setValue('CNPJ');
        this.tipoDocumentoList = tipoDocumentoListPJ;
        this.tipoPessoa = 'JURIDICA';
        break;
      default:
        this.formPersonal.controls['documento'].reset();
        this.formPersonal.controls['tipoDocumento'].reset();
        break;
    }
  }

  getPessoaDoc() {
    if (
      this.formPersonal.value.tipoDocumento &&
      this.formPersonal.controls['documento'].valid
    ) {
      this.apiService
        .getPessoaByDoc(
          this.formPersonal.value.documento,
          this.formPersonal.value.tipoDocumento
        )
        .subscribe({
          next: (res) => {
            this.paisEndereco = res.paisEndereco?.nome;
            this.pessoa = res;
            this.formPersonal.setValue({ ...this.formPersonal.value, ...res });
          },
          error: (erro) => {
            console.log(erro);
          },
        });
    }
  }

  activeValidatorsFisica() {
    this.formPersonal.controls['dataNascimento'].setValidators([
      Validators.required,
    ]);
    if (this.allValidations) {
      this.formPersonal.controls['genero'].setValidators([Validators.required]);
      this.formPersonal.controls['paisOrigem'].setValidators([
        Validators.required,
      ]);
      this.formPersonal.controls['celular'].setValidators([
        Validators.required,
      ]);
    }
    this.formPersonal.updateValueAndValidity();
  }

  clearFormErrors() {
    Object.keys(this.formPersonal.controls).forEach((key) => {
      this.formPersonal.controls[key].setErrors(null);
      this.formPersonal.controls[key].updateValueAndValidity();
    });
  }

  changePais() {
    this.paisEndereco = this.formPersonal.value.paisEndereco.nome;
    this.clearFormErrors();
    this.formPersonal.updateValueAndValidity();
  }

  changeTypeDoc() {
    this.formPersonal.controls['documento'].setValidators(null);
    this.formPersonal.controls['documento'].reset();
    switch (this.formPersonal.value.tipoDocumento) {
      case 'CPF':
        this.formPersonal.controls['documento'].setValidators([
          Validators.required,
          Validacoes.ValidaCpf,
        ]);
        break;
      case 'CNPJ':
        this.formPersonal.controls['documento'].setValidators([
          Validators.required,
          Validacoes.validaCNPJ,
        ]);
        break;
      default:
        break;

    }
    this.formPersonal.updateValueAndValidity();
  }

  sources = [
    this.apiService.getGeneros(),
    this.apiService.getPaises(),
    this.apiService.getTiposLogradouro(),
  ];

  async loadInitial() {
    forkJoin(this.sources)
      .pipe(
        map(([generos, paises, logradouros]) => {
          return {
            generos: generos,
            paises: paises,
            logradouros: logradouros,
          };
        })
      )
      .subscribe({
        next: (res: any) => {
          this.paisList = res.paises;
          this.generoList = res.generos;
          this.tipoLogradouroList = res.logradouros;
        },
        error: (err) => {},
      });
  }

  onSave() {
    // this.formPersonal.setValue({
    //   ...pessoaTeste,
    // });
    if (this.validateForm()) {
      this.saveForm.emit({ ...this.pessoa, ...this.formPersonal.value });
    }
  }

  getMaskDocument() {
    switch (this.formPersonal.get('tipoDocumento')?.value) {
      case 'CPF':
        return '000.000.000-00';
      case 'CNPJ':
        return '00.000.000/0000-00';
      default:
        return null;
    }
  }

  searchCep() {
    this.loadingCep = true;
    if (
      this.formPersonal.get('paisEndereco')?.value.nome === 'BRASIL' &&
      this.formPersonal.get('cep')?.value.length === 8
    ) {
      this.apiService.getCep(this.formPersonal.get('cep')?.value).subscribe({
        next: (res) => {
          this.formPersonal.setValue({
            ...this.formPersonal.value,
            tipoLogradouro: res.logradouro.split(' ')[0],
            cep: res.cep,
            logradouro: res.logradouro,
            complemento: res.complemento,
            bairro: res.bairro,
            cidade: res.localidade,
            uf: res.uf,
            codigoCidadeIbge: res.ibge,
          });
        },
        error: () => {
          this.loadingCep = false;
        },
      });
    }
  }

  phoneChange(key: string, value: string) {
    this.formPersonal.setValue({ ...this.formPersonal.value, [key]: value });
  }

  getCepMask() {
    switch (this.formPersonal.get('paisEndereco')?.value.nome) {
      case 'BRASIL':
        return '00000-000';
      default:
        return null;
    }
  }

  isOver18(dob: string): boolean {
    const [ano, mes, dia] = dob.split('-');
    let birth = new Date(+ano, +mes - 1, +dia, 0, 0, 0);
    const now = new Date();
    const ageInMs = now.getTime() - birth.getTime();
    const ageInYears = ageInMs / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 18;
  }

  validateForm() {
    this.clearFormErrors();
    console.log(this.formPersonal)
    let formValues: PessoaDTO = this.formPersonal.value;
    if (this.isEmpty(formValues.nome)) {
      this.formPersonal.controls['nome'].setErrors({ required: true });
    }
    if (this.isEmpty(formValues.tipo)) {
      this.formPersonal.controls['tipo'].setErrors({ required: true });
    }
    if (formValues.tipo === 'FISICA') {
      if (!formValues.dataNascimento) {
        this.formPersonal.controls['dataNascimento'].setErrors({
          required: true,
        });
      }else{
        if (!this.isOver18(formValues.dataNascimento)) {
          if (this.allValidations) {
            this.formPersonal.controls['dataNascimento'].setErrors({
              agePrincipal: true,
            });
          }
        }else{
          if (this.isEmpty(formValues.tipoDocumento)) {
            this.formPersonal.controls['tipoDocumento'].setErrors({
              required: true,
            });
          }
          if (this.isEmpty(formValues.documento)) {
            this.formPersonal.controls['documento'].setErrors({ required: true });
          }
        }
      }
      if (this.allValidations) {
        if (this.isEmpty(formValues.email)) {
          this.formPersonal.controls['email'].setErrors({ required: true });
        }
        if (this.isEmpty(formValues.paisOrigem?.uuid)) {
          this.formPersonal.controls['paisOrigem'].setErrors({
            required: true,
          });
        }
        if (this.isEmpty(formValues.genero?.uuid)) {
          this.formPersonal.controls['genero'].setErrors({ required: true });
        }
      }
    }

    if (this.isEmpty(formValues.paisEndereco?.uuid)) {
          this.formPersonal.controls['paisEndereco'].setErrors({
            required: true,
          });
        }
    if (this.allValidations) {
      //se não for pessoa júridica estrangeira e estiver vazio
      if (!this.isPjEstrangeira() && this.isEmpty(formValues.tipoDocumento)) {
        this.formPersonal.controls['tipoDocumento'].setErrors({
          required: true,
        });
      }
       if ((!this.isPjEstrangeira() && this.isEmpty(formValues.documento))) {
        this.formPersonal.controls['documento'].setErrors({ required: true });
      }
      if (this.isEmpty(formValues.celular)) {
        this.formPersonal.controls['celular'].setErrors({ required: true });
      }
    }

    if (this.isEmpty(formValues.cep)) {
      this.formPersonal.controls['cep'].setErrors({ required: true });
    }
    if (this.isEmpty(formValues.logradouro)) {
      this.formPersonal.controls['logradouro'].setErrors({ required: true });
    }
    if (this.isEmpty(formValues.tipoLogradouro) && formValues.paisEndereco?.nome === "BRASIL") {
      this.formPersonal.controls['tipoLogradouro'].setErrors({ required: true });
    }
    if (this.isEmpty(formValues.numero)) {
      this.formPersonal.controls['numero'].setErrors({ required: true });
    }
    if (this.isEmpty(formValues.bairro)) {
      this.formPersonal.controls['bairro'].setErrors({ required: true });
    }
    if (this.isEmpty(formValues.uf)) {
      this.formPersonal.controls['uf'].setErrors({ required: true });
    }
    if (this.isEmpty(formValues.cidade)) {
      this.formPersonal.controls['cidade'].setErrors({ required: true });
    }
    return this.formPersonal.valid;
  }

  isEmpty(value: string | number | null): boolean {
    if (value == null) return true;
    if (typeof value == 'string') {
      return value.trim().length == 0;
    }
    if (isNaN(value as number)) return true;
    return false;
  }

  isPjEstrangeira(){
    let formValues: PessoaDTO = this.formPersonal.value;
    return formValues.tipo === 'JURIDICA' && this.paisEndereco!=null && this.paisEndereco !== 'BRASIL';
    }
}
